<template>
  <div class="arena min-h-screen text-white flex flex-col arena">
    <Navigation/>
    <div v-if="!ethereum.connected">
      <div class="text-center text-2xl mt-20">
        Please connect your wallet to continue...
      </div>
      <div class="text-center mt-10 text-2xl">
        <ConnectButton/>
      </div>
    </div>
    <div v-else-if="error" class="mt-20 text-center text-3xl font-pixel">
      Invalid address.
    </div>
    <div v-else-if="loading" class="mt-10">
      <Spinner/>
    </div>
    <div v-else-if="!active" class="text-center m-10">
      <div class="bg-white text-black p-5 rounded-md shadow-md w-full xl:w-1/3 m-auto">
        <div class="text-2xl font-bold my-5">
          The arena is currently closed. Check back later.
        </div>
      </div>
    </div>
    <div v-else-if="gameOver" class="p-10 flex-grow flex flex-col justify-center">
      <div class="text-center w-full">
        <div class="text-xl my-5">
          Where {{ fallenCount }} brave souls perished, 1 mighty warrior prevailed.
        </div>
        <div class="text-4xl font-bold font-pixel">
          Meet Your Champion
        </div>
        <div class="champion">
          <div style="width: 350px; height: 350px;" class="mx-auto mt-10">
            <ChainFace :id="champion"/>
          </div>
          <div class="text-4xl mt-10 font-pixel">
            #{{ champion.toString().padStart(5, '0') }}
          </div>
        </div>
        <div class="mt-10 text-2xl" v-if="ownsChampion">
          <button class="btn" @click="leaveArena(champion)">Claim Your Prize</button>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full flex-grow" v-else>
      <div v-if="open" class="arena-header text-center p-20">
        <div class="text-3xl font-pixel text-yellow-300">
          The Arena is Open
        </div>
        <div class="mt-2 text-xl mb-10">
          Head to your wallet page to volunteer warriors to compete in the arena.
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 w-full xl:w-1/2 mt-5 mx-auto">
          <div class="bg-gray-800 rounded-md shadow-md py-5 px-3 flex flex-col justify-center">
            <div class="text-base uppercase text-yellow-200">Bounty</div>
            <div class="text-4xl font-bold">{{ bounty }} Ξ</div>
            <div class="text-base">{{ bountyPerWarrior }} Ξ per Warrior</div>
          </div>
          <div class="bg-gray-800 rounded-md shadow-md py-5 px-3 flex flex-col justify-center">
            <div class="text-base uppercase text-yellow-200">Warriors</div>
            <div class="text-4xl font-bold">{{ aliveCount }}</div>
          </div>
          <div class="bg-gray-800 rounded-md shadow-md py-5 px-3 flex flex-col justify-center">
            <div class="text-base uppercase text-yellow-200">Blocks until Start</div>
            <div class="text-4xl font-bold">{{ blocksUntilStart }}</div>
            <div class="text-base">Approx. {{ timeUntilStart }}</div>
          </div>
        </div>
        <div class="mt-10">
          <a href="#" @click.prevent="showInfo = true" class="btn text-xl">How To Play</a>
          <div class="text-xl mt-5 bg-red-700 text-white p-2 rounded-md w-full md:w-1/2 mx-auto">
            Please note: the arena is a completely optional minigame. You do not have to enter if you do not wish to.
          </div>
        </div>
      </div>
      <div class="arena-header text-center p-20" v-else>
        <div class="text-3xl font-pixel text-yellow-300 mb-10">
          Round {{ round }}
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 w-full xl:w-1/2 mt-5 mx-auto">
          <div class="bg-gray-800 rounded-md shadow-md py-5 px-3 flex flex-col justify-center">
            <div class="text-base uppercase text-yellow-200">Bounty</div>
            <div class="text-4xl font-bold">{{ bounty }} Ξ</div>
            <div class="text-base">{{ bountyPerWarrior }} Ξ per Warrior</div>
          </div>
          <div class="bg-gray-800 rounded-md shadow-md py-5 px-3 flex flex-col justify-center">
            <div class="text-base uppercase text-yellow-200">Round Ends (Blocks)</div>
            <div class="text-4xl font-bold" v-if="nextFeed > 1">{{ nextFeed }}</div>
            <div class="text-4xl font-bold" v-else>Soon</div>
            <div class="text-base" v-if="nextFeed > 5">Approx. {{ timeUntilFeed }}</div>
            <div class="text-base" v-else>Less than 1 min</div>
          </div>
          <div class="bg-gray-800 rounded-md shadow-md py-5 px-3 flex flex-col justify-center">
            <div class="text-base uppercase text-yellow-200">Deaths Next Round</div>
            <div class="text-4xl font-bold">{{ hunger }}</div>
            <div class="text-base">{{ chanceOfDeath }}% chance of death</div>
          </div>
          <div class="bg-gray-800 rounded-md shadow-md py-5 px-3 flex flex-col justify-center">
            <div class="text-base uppercase text-yellow-200">Warriors Remaining</div>
            <div class="text-4xl font-bold">{{ aliveCount }}</div>
          </div>
          <div class="bg-gray-800 rounded-md shadow-md py-5 px-3 flex flex-col justify-center">
            <div class="text-base uppercase text-yellow-200">Cowards</div>
            <div class="text-4xl font-bold">{{ leftCount }}</div>
          </div>
          <div class="bg-gray-800 rounded-md shadow-md py-5 px-3 flex flex-col justify-center">
            <div class="text-base uppercase text-yellow-200">Fallen Heroes</div>
            <div class="text-4xl font-bold">{{ fallenCount }}</div>
          </div>
        </div>
        <div class="mt-10">
          <div class="text-xl mt-5 bg-red-700 text-white p-2 rounded-md w-full md:w-1/2 mx-auto">
            Arena closed - you can no longer enter new warriors into the arena.
          </div>
        </div>
      </div>
      <div v-if="last100" class="flex w-full content-stretch flex-grow bg-gray-200">
        <div class="flex flex-col flex-grow bg-gray-200 text-black px-10 py-5">
          <div class="text-center text-3xl font-bold">
            The Final 100
          </div>
          <div class="text-center mt-1 border-b border-gray-400 pb-5">
            The final 100 warriors. Who will be the champion?
          </div>
          <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-10 gap-5 mt-10" :key="round">
            <a href="#" @click.prevent="leaveArena(warrior)" v-for="warrior of last100Alive" :key="warrior"
               class="bg-white p-2 rounded-md shadow-md">
              <ChainFace :id="warrior"/>
              <div class="flex justify-between items-center mt-1">
                <div>
                  <button class="btn text-xs" v-if="round > 0">Leave</button>
                </div>
                <div class="font-pixel text-xs 2xl:text-sm">#{{ warrior.toString().padStart(5, '0') }}</div>
              </div>
            </a>
            <a href="#" @click.prevent v-for="warrior of last100Fallen" :key="warrior"
               class="bg-white p-2 rounded-md shadow-md">
              <ChainFaceLowRes :id="warrior"/>
              <div class="flex justify-between items-center mt-1">
                <div></div>
                <div class="font-pixel text-xs 2xl:text-sm">#{{ warrior.toString().padStart(5, '0') }}</div>
              </div>
            </a>
            <div v-for="warrior of last100Unowned" :key="warrior"
                 class="bg-white p-2 rounded-md shadow-md" :style="{opacity: isCoward(warrior) ? '0.25' : '1'}">
              <ChainFaceLowRes :id="warrior"/>
              <div class="flex justify-between items-center mt-1">
                <div></div>
                <div class="font-pixel text-xs 2xl:text-sm">#{{ warrior.toString().padStart(5, '0') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full content-stretch flex-grow" v-else>
        <div class="flex-grow bg-gray-800 text-white px-10 py-5" style="flex-basis: 50%;">
          <div class="text-center text-3xl font-bold">
            Fallen
          </div>
          <div class="text-center mt-1 border-b border-gray-600 pb-5">
            Here lie your fallen warriors. May they rest in peace.
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 mt-10">
            <div v-for="warrior of fallen" :key="warrior" class="bg-red-900 p-2 rounded-md shadow-md">
              <ChainFace :id="warrior"/>
              <div class="font-pixel text-right mt-1 text-sm">#{{ warrior.toString().padStart(5, '0') }}</div>
            </div>
          </div>
        </div>
        <div class="separator"></div>
        <div class="flex flex-col flex-grow bg-gray-200 text-black px-10 py-5" style="flex-basis: 50%;">
          <div class="text-center text-3xl font-bold">
            Alive
          </div>
          <div class="text-center mt-1 border-b border-gray-400 pb-5">
            Your heroic (and still living) warriors.
          </div>
          <div v-if="!ethereum.account" class="text-center flex-grow flex flex-col justify-center items-center">
            <div class="text-sm">Connect your wallet to see your warriors.</div>
            <ConnectButton class="text-xl mt-3"/>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 mt-10"
               v-if="alive.length > 0" :key="round">
            <a href="#" @click.prevent="leaveArena(warrior)" v-for="warrior of alive" :key="warrior"
               class="bg-white p-2 rounded-md shadow-md">
              <ChainFace :id="warrior"/>
              <div class="flex justify-between items-center mt-1">
                <div>
                  <button class="btn text-xs" v-if="round > 0">Leave</button>
                </div>
                <div class="font-pixel text-xs 2xl:text-sm">#{{ warrior.toString().padStart(5, '0') }}</div>
              </div>
            </a>
          </div>
          <div v-else-if="open" class="text-center flex-grow flex flex-col justify-center items-center">
            <div class="text-base">Select warriors to enter the arena from your wallet page.</div>
            <router-link class="btn text-xl mt-3" :to="{name: 'Wallet'}">Volunteer Your Warriors</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
  <Modal v-if="showInfo" @close="showInfo = false">
    <div class="bg-white p-10 text-lg">
      <div class="text-3xl font-pixel">The Arena</div>
      <div class="text-xl font-pixel mt-5">What Is It?</div>
      <p class="mt-5">
        The arena is a simple idle minigame for your warrior ChainFaces. The choices you must make are simple but the
        rewards and consequences are significant.
      </p>
      <div class="text-xl font-pixel mt-5">How To Play</div>
      <p class="mt-5">When the arena opens, there is a period of 6969 block (approx. 25 hours) where players may send
        one or more of their ChainFaces to it. Once a ChainFace has been sent to the arena, you cannot leave the arena
        until at least one round has passed. Tokens in the arena are not transferrable or sellable on OpenSea. They are
        transferred out of your control and into the contract so you will not see them in your wallet while they are in
        the arena. You may only join the arena during this 6969 block period. If you don't join during this period, you
        will sit the entire game out.</p>
      <p class="mt-5">Once the game begins, rounds pass every 69 blocks (approx. 15 minutes). At the conclusion of a
        round, a percentage (0.2% of the population exceeding 1,000) of the participants are killed. You can see how
        many will be killed at the end of the round on the arena UI page. <span class="text-red-600 font-bold">It is extremely important to note that a dead
        ChainFace is lost forever. You will no longer own the token and it will be inaccessible to anybody. For all
        intents and purposes, the token is burned. Please understand this point before deciding to play.</span></p>
      <p class="mt-5"> Every 10 rounds in the arena your ChainFace survives, you will be awarded with one well-deserved
        scar which is visible on your ChainFace's artwork. You will see it immediately on our website but will need to
        refresh metadata on OpenSea for it to show there. Your token will also have a trait that increments every round
        survived. This trait is called "Arena Score" and is visible on OpenSea under "Levels". By nature of how the
        arena mechanics work, tokens with more scars and higher "Arena Score" will become progressively rarer.</p>
      <p class="mt-5">After each round, you may chose to leave the arena, reclaiming your token. Once you have left the
        arena, you can transfer and trade your token normally again and it will appear in your wallet (hopefully with
        some cool battle scars). Once you leave the arena, you may not reenter. Your time in the arena will have come to
        an end. You will also receive a proportion of the bounty which is calculated as remaining_bounty /
        remaining_players. So if the current bounty is 1 ETH and there are 100 players, leaving will yield 0.01 ETH upon
        leaving the arena.
      </p>
      <p class="mt-5"> The game ends when there is only 1 ChainFace left standing. This one brave warrior will be
        heralded as our champion for the rest of time.</p>
      <p class="mt-5"> There is only one arena event, it does not reset after the game concludes.</p>
      <div class="text-xl font-pixel mt-5">Leaving The Arena</div>
      <p class="mt-5">There is a short period at the end of each round where leaving the arena is forbidden and if you
        transaction is mined at this time it will revert. It is recommended that you leave the arena at the start of
        each round and use a gas value that ensures it is mined quickly.</p>
    </div>
  </Modal>
</template>

<script>
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Spinner from '../components/Spinner';
import { Ethereum } from '../js/ethereum';
import ChainFace from '../components/ChainFace';
import { ethers } from 'ethers';
import { notifyError, notifyTransaction } from '../js/notifications';
import ConnectButton from '../components/ConnectButton';
import Modal from '../components/Modal';
import ChainFaceLowRes from '../components/ChainFaceLowRes';

const ENS = require('ethereum-ens');

const LAST_100 = [17665,1867,26776,24729,7641,17724,9122,16531,24749,17116,18224,8637,3463,3480,17171,23446,24753,975,14020,13236,7846,22733,25442,9271,15708,20695,15549,3465,26133,13932,25562,26875,17995,17372,10012,19959,19570,16791,21409,10187,4684,7531,21920,22181,18500,5807,26313,6942,12212,642,2449,15366,24436,2691,19457,25197,25116,5761,22701,5621,9112,19024,20269,10182,20810,1292,14884,21145,26519,26154,23249,20904,6418,24297,26894,16902,19989,16903,2699,18088,11019,26478,3274,2614,21289,26756,12650,1591,25872,16043,26274,7020,18763,16054,16892,9956,16991,24257,5266,17438]

function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function promiseAllInBatches (promises, batchSize, delay = 500) {
  let position = 0;
  let results = [];
  while (position < promises.length) {
    const promisesForBatch = promises.slice(position, position + batchSize);
    results = [...results, ...await Promise.all(promisesForBatch)];
    position += batchSize;
    await (sleep(delay));
  }
  return results;
}

export default {
  name: 'Arena',
  components: { ChainFaceLowRes, Modal, ConnectButton, ChainFace, Spinner, Footer, Navigation },
  data () {
    return {
      loading: true,
      ethereum: Ethereum.state,
      showInfo: false,
      blocksPerRound: 0,
      fallenCount: 0,
      aliveCount: 0,
      leftCount: 0,
      nextFeed: 0,
      round: 0,
      bounty: 0,
      hunger: 0,
      chanceOfDeath: '-%',
      bountyPerWarrior: 0,
      champion: 0,
      ownsChampion: false,
      blocksUntilStart: 0,
      timeUntilStart: '--:--:--',
      timeUntilFeed: '--:--:--',
      error: false,
      open: false,
      active: false,
      gameOver: false,
      hungry: false,
      last100: true,
      last100Alive: [],
      last100Fallen: [],
      last100Unowned: [],
      alive: [],
      fallen: [],
      cowards: []
    };
  },
  watch: {
    '$route': {
      immediate: true,
      handler () {
        this.loading = true;
        this.error = false;
        this.address = this.$route.params.address;
      }
    }
  },
  mounted () {
    this.getData();
  },
  methods: {
    async leaveArena (id) {
      if (!this.gameOver && this.nextFeed <= 1) {
        notifyError('Cannot leave now until current round ends.');
      } else if (this.round === 0) {
        notifyError('Cannot leave arena until at least 1 round has passed.');
      } else {
        try {
          const tx = await Ethereum.contract.leaveArena(id);
          notifyTransaction(tx, 'Leave arena transaction sent.');
        } catch (e) {
          console.error(e.message);
          notifyError('Something went wrong.');
        }
      }
    },
    isCoward (id) {
      return this.cowards.includes(id);
    },
    async getData () {
      if (this._isDestroyed) {
        return;
      }

      try {
        if (this.blocksPerRound === 0) {
          this.blocksPerRound = (await Ethereum.contract.blocksPerRound()).toNumber();
        }

        const currentBlock = await Ethereum.provider.getBlockNumber();

        const info = await Ethereum.contract.arenaInfo();

        this.aliveCount = info.alive.toNumber();
        this.fallenCount = info.fallen.toNumber();

        this.leftCount = 17032 - this.fallenCount - this.aliveCount;

        this.round = info.currentRound.toNumber();
        this.nextFeed = info.nextFeed.toNumber();
        this.champion = info.champion.toNumber();
        this.bounty = (Math.floor(parseFloat(ethers.utils.formatEther(info.bounty)) * 100) / 100).toFixed(2);
        this.hunger = info.hunger.toNumber();
        if (this.aliveCount > 0) {
          this.bountyPerWarrior = parseFloat(ethers.utils.formatEther(info.bounty.div(this.aliveCount))).toFixed(4);
        } else {
          this.bountyPerWarrior = '0.00';
        }
        this.open = info.open;
        this.active = info.active;
        this.hungry = info.hungry;
        this.gameOver = info.gameOver;
        this.blocksUntilStart = info.entryClosedBlock.toNumber() - currentBlock;

        this.chanceOfDeath = ((this.hunger / this.aliveCount) * 100).toFixed(2);

        const secondsUntilStart = this.blocksUntilStart * 13;
        let hours = Math.floor(secondsUntilStart / 60 / 60);
        let minutes = Math.floor((secondsUntilStart - (hours * 60 * 60)) / 60);
        this.timeUntilStart = hours.toString().padStart(2, '0') + 'h ' + minutes.toString().padStart(2, '0') + 'm';

        const secondsUntilFeed = (this.nextFeed) * 13;
        minutes = Math.floor((secondsUntilFeed) / 60);
        this.timeUntilFeed = minutes.toString() + ' mins';

        let address = this.address ? this.address : this.ethereum.account;
        console.log(address);

        if (!ethers.utils.isAddress(address)) {
          const ens = new ENS(Ethereum.web3);

          try {
            address = await ens.resolver(address).addr();
          } catch (e) {
            address = null;
            this.error = true;
            return;
          }
        } else {
        }

        if (address) {
          const [fallen, alive] = await Promise.all([Ethereum.contract.ownerWarriors(address, false), Ethereum.contract.ownerWarriors(address, true)]);

          this.fallen = [];
          this.alive = [];

          for (const warrior of fallen) {
            this.fallen.push(warrior.toNumber());
          }

          for (const warrior of alive) {
            this.alive.push(warrior.toNumber());
          }

          if (this.last100) {
            this.totalOwned = (await Ethereum.contract.balanceOf(address)).toNumber();

            this.last100Unowned = [];
            this.last100Alive = [];
            this.last100Fallen = [];

            for (let i = 0; i < LAST_100.length; i++) {
              let id = LAST_100[i];
              if (this.fallen.includes(id)) {
                this.last100Fallen.push(id);
              } else if (this.alive.includes(id)) {
                this.last100Alive.push(id);
              } else {
                this.last100Unowned.push(id);
              }
            }

            let promises = [];

            for (let i = 0; i < this.last100Unowned.length; i++) {
              promises.push(Ethereum.contract.ownerOf(this.last100Unowned[i]));
            }

            const owners = await promiseAllInBatches(promises, 20);

            this.cowards = [];

            for (let i = 0; i < owners.length; i++) {
              if (owners[i] !== '0x7039D65E346FDEEBbc72514D718C88699c74ba4b' && owners[i] !== '0x93a796B1E846567Fe3577af7B7BB89F71680173a') {
                this.cowards.push(this.last100Unowned[i]);
              }
            }
          }
        } else {
          this.last100Unowned = [];
          this.last100Alive = [];
          this.last100Fallen = [];
          this.cowards = [];
          this.fallen = [];
          this.alive = [];
        }

        if (this.alive.includes(this.champion)) {
          this.ownsChampion = true;
        } else {
          this.ownsChampion = false;
        }

        this.loading = false;
      } catch (e) {
        console.error(e);
      }

      setTimeout(this.getData.bind(this), 5000);
    }
  }
};
</script>

<style lang="scss">
.champion {
  background-image: url('../assets/luarels.png');
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 20px;
}

.arena {
  .separator {
    background: url("../assets/arena-separator.png");
    flex-basis: 10px;
    flex-shrink: 0;
  }
}

.arena {
  background: url("../assets/arena-bg.png");
}

.arena-header {
  box-shadow: inset 0 -5px 10px 0 rgba(0, 0, 0, 0.2);
}
</style>