<template>
  <div class="chain-face-lr" @click="logMetadata">
    <img :src="svg" class="mx-auto" style="width: 100%;"/>
  </div>
</template>

<script>
import { Ethereum } from '../js/ethereum';

export default {
  name: 'ChainFaceLowRes',
  props: ['id'],
  data () {
    return {
      svg: ''
    };
  },
  watch: {
    'id': {
      immediate: true,
      async handler () {
        this.svg = 'data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'400\' height=\'400\' style=\'background-color:RGB(255,255,255);\'><text x=\'50%\' y=\'50%\' dominant-baseline=\'middle\' text-anchor=\'middle\' font-size=\'30px\'>loading...</text></svg>';
        const [face, owner] = await Promise.all([Ethereum.contract.assembleFace(this.id), Ethereum.contract.ownerOf(this.id)]);

        const fallen = owner === '0x7039D65E346FDEEBbc72514D718C88699c74ba4b';

        if (fallen) {
          this.svg = 'data:image/svg+xml,' + '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'400\' height=\'400\' style=\'background-color:RGB(255,128,128)\'><text x=\'50%\' y=\'50%\' dominant-baseline=\'middle\' text-anchor=\'middle\' font-size=\'75px\' fill=\'RGB(0,0,0)\'>' + face + '</text></svg>';
        } else {
          this.svg = 'data:image/svg+xml,' + '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'400\' height=\'400\' style=\'background-color:RGB(255,255,243)\'><text x=\'50%\' y=\'50%\' dominant-baseline=\'middle\' text-anchor=\'middle\' font-size=\'75px\' fill=\'RGB(0,0,0)\'>' + face + '</text></svg>';
        }
      }
    }
  },
  async mounted () {
  },
  methods: {
    async logMetadata () {
      console.log(await Ethereum.contract.tokenURI(this.id));
    }
  }
};
</script>