<template>
  <div class="graveyard min-h-screen text-white flex flex-col arena">
    <Navigation/>
    <div v-if="!ethereum.connected">
      <div class="text-center text-2xl mt-20">
        Please connect your wallet to continue...
      </div>
      <div class="text-center mt-10 text-2xl">
        <ConnectButton/>
      </div>
    </div>
    <div v-else-if="loading" class="mt-5">
      <Spinner/>
    </div>
    <div v-else>
      <div class="text-center font-pixel text-4xl mt-20">Graveyard</div>
      <div class="text-center mt-5 px-5">
        We mourn the loss of these {{ fallenCount }} fallen heroes. They are in their happy place now.
      </div>
      <div class="grid grid-cols-2 md:grid-cols-4 2xl:grid-cols-8 p-5 w-full md:w-2/3 mx-auto gap-4 mt-10">
        <div class="bg-red-900 text-white p-2 rounded-md shadow-md" v-for="face in faces">
          <div class="relative">
            <transition name="fade">
              <ChainFace :id="face" :key="face"/>
            </transition>
          </div>
          <div class="font-pixel text-right mt-1 text-sm">#{{ face.toString().padStart(5, '0') }}</div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Spinner from '../components/Spinner';
import { Ethereum } from '../js/ethereum';
import ChainFace from '../components/ChainFace';
import ConnectButton from '../components/ConnectButton';
import Modal from '../components/Modal';

const HAPPY_FACE_PLACE = '0x7039D65E346FDEEBbc72514D718C88699c74ba4b';

export default {
  name: 'Arena',
  components: { Modal, ConnectButton, ChainFace, Spinner, Footer, Navigation },
  data () {
    return {
      loading: true,
      faces: [],
      fallenCount: 0,
      page: 0,
      ethereum: Ethereum.state,
    };
  },
  mounted () {
    this.getData();
  },
  methods: {
    async getData () {
      if (this._isDestroyed) {
        return;
      }

      const info = await Ethereum.contract.arenaInfo();
      this.fallenCount = info.fallen.toNumber();

      let total = await Ethereum.contract.balanceOf(HAPPY_FACE_PLACE);
      let perPage = 8 * 4;

      let promises = [];
      for (let i = 0; i < perPage; i++) {
        const face = Math.floor(Math.random() * total);
        promises.push(Ethereum.contract.tokenOfOwnerByIndex(HAPPY_FACE_PLACE, face));
      }
      this.faces = await Promise.all(promises);

      this.loading = false;
      setTimeout(this.changeFace.bind(this), 5000);
    },
    async changeFace () {
      try {
        let total = await Ethereum.contract.balanceOf(HAPPY_FACE_PLACE);
        const face = Math.floor(Math.random() * total);
        const replace = Math.floor(Math.random() * 8 * 4);
        this.faces[replace] = await Ethereum.contract.tokenOfOwnerByIndex(HAPPY_FACE_PLACE, face);
      } catch(e) {
      }

      setTimeout(this.changeFace.bind(this), 500);
    }
  }
};
</script>

<style lang="scss">
.graveyard {
  background: url("../assets/arena-bg.png");
}

.fade-leave-active {
  position: absolute;
  transition: all 5s ease-in-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>