<template>
  <div class="modal-container" @click="$emit('close')">
    <div class="modal" @click.stop>
      <div class="modal__slot">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Modal',
  props: {
    variant: {
      type: String,
      default: null
    },
    closable: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
@keyframes modalScale {
  0% {
    transform: translate3d(0, -10%, 0);
    visibility: visible;
  }
  to {
    transform: translateZ(0);
  }
}

.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  margin: 30px;

  @media (max-width: 600px) {
    margin: 0;
  }

  &::before {
    background: rgba(33, 34, 36, 0.75);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
  }

  .modal {
    width: 100%;
    max-width: 740px;
    max-height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--color__body__background);
    color: var(--color__text);
    box-sizing: border-box;
    position: relative;
    animation: modalScale 0.4s backwards ease-in-out;
  }
}
</style>
