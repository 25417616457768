<template>
  <div class="chain-face" @click="logMetadata">
    <img :src="svg" class="mx-auto" style="width: 100%;"/>
  </div>
</template>

<script>
import { Ethereum } from '../js/ethereum';


export default {
  name: 'ChainFace',
  props: ['id'],
  data() {
    return {
      svg: ''
    };
  },
  watch: {
    'id': {
      immediate: true,
      async handler() {
        this.svg = 'data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'400\' height=\'400\' style=\'background-color:RGB(255,255,255);\'><text x=\'50%\' y=\'50%\' dominant-baseline=\'middle\' text-anchor=\'middle\' font-size=\'30px\'>loading...</text></svg>';
        let svgData = new Buffer(await Ethereum.contract.renderSvg(this.id));
        this.svg = 'data:image/svg+xml;base64,' + svgData.toString('base64');
      }
    }
  },
  async mounted() {
  },
  methods: {
    async logMetadata() {
      console.log(await Ethereum.contract.tokenURI(this.id));
    }
  }
};
</script>